import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { env } from '@/env.mjs';

const client = new ApolloClient({
  link: ApolloLink.from([
    createUploadLink({
      uri: `${env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
      fetchOptions: {
        mode: `cors`,
      },
      credentials: `include`,
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: `no-cache`,
      errorPolicy: `none`,
    },
    query: {
      fetchPolicy: `no-cache`,
      errorPolicy: `none`,
    },
  },
});

export default client;
